<template>
  <b-card
    no-body
    class="card-developer-meetup align-items-center"
    :img-src="require('@/assets/images/logo/Profile_icon.png')"
    img-alt="Card image email"
    img-top
  >
    <b-card-body class="d-flex flex-column align-items-center justify-content-end">
      <div class="meetup-header d-flex flex-row mb-0">
        <div class="meetup-day font-weight-bolder">
          <h6 class="mb-0">
            {{ dayOfWeek }}
          </h6>
          <h3 class="mb-0">
            {{ dayOfMondth }}
          </h3>
        </div>
        <div class="my-auto">
          <b-card-text class="font-weight-bolder">
            {{ $t('Welcome') }}!
          </b-card-text>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BCardText,
  },
  data() {
    return {

    }
  },
  computed: {
    dayOfWeek() {
      return new Date().toLocaleString(this.$i18n.locale, { timeZone: 'Europe/Athens', weekday: 'short' })
    },
    dayOfMondth() {
      return new Date().getDate()
    },
  },
}
</script>

<style scoped>

.card-developer-meetup img {
  width: 250px;
}

</style>
