<template>
  <div class="d-flex flex-column align-items-center">
    <div class="col-12 text-center">
      <b-card-title>
        {{ $t('Do you need help?') }}
      </b-card-title>
    </div>
    <div class="col-sm-8 col-md-4 text-center">
      <b-card-text class="text-center">
        {{ $t('Find the answer you are looking for at ') }}
        <b-link :to="{name: 'faq'}">
          {{ $t('frequently asked questions') }}
        </b-link>
        {{ $t('or') }} {{ $t('contact us') }}
        <a href="mailto:info@when.org.gr">info@when.org.gr</a>
      </b-card-text>
    </div>
  </div>
</template>

<script>
import {
  BCardText,
  BCardTitle,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCardText,
    BCardTitle,
    BLink,
  },
  data() {
    return {

    }
  },
}
</script>

<style scoped>

</style>
